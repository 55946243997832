import { Col, Modal, Row, Typography } from 'antd';
import { get, isEmpty, isEqual } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BackgroundJob from 'pages/BackgroundJob';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, useRouteMatch, Route, useHistory, Redirect, useParams } from 'react-router-dom';
import catWithSpinner from 'assets/video/cat_with_spinner.mp4';
import { syncDataWithAmo, syncDataWithMango } from 'redux/entities/amoIntegration/operations';
import moment from 'core/moment';
import AmoFields from '../IntegrationSettingsModules/AmoFields';
import { INTEGRATIONS, SYNC_DATA_PERIODS } from 'core/utils/constants';
import { syncDataWithBinotel } from 'redux/entities/binotelIntegration/operations';
import { syncDataWithBitrix } from 'redux/entities/bitrixIntegration/operations';
import { syncDataWithUsedesk } from 'redux/entities/usedeskIntegration/operations';
import { integrationsResource } from 'redux/resources/integrations';
import { syncDataWithZendesk } from 'redux/entities/zendeskIntegration/operations';
import { syncDataWithInfinity } from 'redux/entities/infinityIntegration/operations';
import { syncDataWithBrightPattern } from 'redux/entities/brightPatternIntegration/operations';
import { syncDataWithHelpDeskEddy } from 'redux/entities/helpDeskEddyIntegration/operations';
import { getCurrentUser } from 'redux/selectors/users';
import ManageUsers from '../IntegrationSettingsModules/ManageUserAccounts';
import SyncPeriod from '../IntegrationSettingsModules/SyncPeriod';

const { Title } = Typography;

export const integrationTypeToSyncDataHandler = {
  [INTEGRATIONS.amocrm.type]: syncDataWithAmo,
  [INTEGRATIONS.mango.type]: syncDataWithMango,
  [INTEGRATIONS.bitrix_crm.type]: syncDataWithBitrix,
  [INTEGRATIONS.usedesk.type]: syncDataWithUsedesk,
  [INTEGRATIONS.binotel.type]: syncDataWithBinotel,
  [INTEGRATIONS.zendesk.type]: syncDataWithZendesk,
  [INTEGRATIONS.help_desk_eddy.type]: syncDataWithHelpDeskEddy,
  [INTEGRATIONS.infinity.type]: syncDataWithInfinity,
  [INTEGRATIONS.bright_pattern.type]: syncDataWithBrightPattern
};

const CreateSaasIntegrationPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const { integrationId, id: userId } = useParams();
  const manageUsersRef = useRef();

  useEffect(() => {
    dispatch(integrationsResource.operations.loadById({ id: integrationId }));
  }, [integrationId]);

  const integration = useSelector(
    state => state.integrationsResource.byIds[integrationId],
    isEqual
  );

  const bitrixIntegrationEveningSynchronizedEnabled = useSelector(
    state =>
      get(
        getCurrentUser(state),
        'organization.settings.bitrixIntegrationEveningSynchronized.enabled',
        false
      ),
    isEqual
  );

  const bitrixIntegrationNightlySynchronizedEnabled = useSelector(
    state =>
      get(
        getCurrentUser(state),
        'organization.settings.bitrixIntegrationNightlySynchronized.enabled',
        false
      ),
    isEqual
  );

  const syncAtTime =
    (integration?.bitrixIntegrationNightlySynchronizedEnabled ||
    bitrixIntegrationNightlySynchronizedEnabled
      ? '04:00'
      : null) ||
    (integration?.bitrixIntegrationEveningSynchronizedEnabled ||
    bitrixIntegrationEveningSynchronizedEnabled
      ? '23:00'
      : null) ||
    '';

  const isDisabledSyncPeriod =
    integration?.bitrixIntegrationNightlySynchronizedEnabled ||
    bitrixIntegrationNightlySynchronizedEnabled ||
    integration?.bitrixIntegrationEveningSynchronizedEnabled ||
    bitrixIntegrationEveningSynchronizedEnabled ||
    false;

  const currentBackgroundJob = useSelector(state => state.backgroundJob.currentBackgroundJob);

  const selectIntegrationPeriod = async fetchFrom => {
    // const fetchFromToISO = moment()
    //   .subtract(SYNC_DATA_PERIODS[fetchFrom].number, SYNC_DATA_PERIODS[fetchFrom].momentUnit)
    //   .toISOString();

    // await dispatch(
    //   integrationTypeToSyncDataHandler[integration.integrationType]({
    //     id: integration.id,
    //     fetchFrom: fetchFromToISO
    //   })
    // );

    return history.push(
      `/user/${userId}/integrations-settings/${integration.integrationType}/${integrationId}`
    );
  };

  const handleSelectUsers = () => {
    history.push(`${url}/fields`);
  };

  const handleSelectFields = () => {
    history.push(`${url}/period`);
  };

  const onBackgroundJobDone = () => {
    if (window.location.pathname.split('/').includes('users')) {
      return manageUsersRef?.current?.load();
    }

    if (window.location.pathname.split('/').includes('period'))
      return history.push(
        `/user/${userId}/integrations-settings/${integration.integrationType}/${integrationId}`
      );
  };

  const onBackgroundJobFailed = () => {
    Modal.error({
      title: `${t('integrationsSettingsPage.createSaasIntegration.errorMessage')} ${t(
        INTEGRATIONS[integration.integrationType].name
      )}`,
      content: t('integrationsSettingsPage.createSaasIntegration.errorMessageContent')
    });
  };

  return (
    <Row>
      <Col span={24}>
        <Switch>
          <Route path={`${path}/period`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>{t('integrationsSettingsPage.createSaasIntegration.sync')}</Title>
              </Col>
              <Col span={24}>
                <SyncPeriod
                  disabled={isDisabledSyncPeriod}
                  okText={t('general.continue')}
                  onOk={selectIntegrationPeriod}
                  syncAtTime={syncAtTime}
                />
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/users`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>{t('general.users')}</Title>
              </Col>
              <Col span={24}>
                <ManageUsers
                  ref={manageUsersRef}
                  okText={t('general.continue')}
                  integrationId={integrationId}
                  integrationInProcess
                  onOk={handleSelectUsers}
                />
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/fields`}>
            <Row gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
              <Col span={24}>
                <Title level={4}>Поля</Title>
              </Col>
              <Col span={24}>
              <AmoFields
                integrationId={integrationId}
                okText={t('general.continue')}
                onOk={handleSelectFields}
              />
              </Col>
            </Row>
          </Route>
          <Redirect exact from={`${path}/`} to={`${path}/users`}/>
        </Switch>
      </Col>
      <Modal
        visible={!isEmpty(currentBackgroundJob)}
        footer={null}
        closable={false}
        maskClosable
        // maskClosable={false}
      >
        {currentBackgroundJob && (
          <BackgroundJob
            text={
              <video autoPlay loop muted playsinline>
                <source src={catWithSpinner} type="video/mp4"/>
              </video>
            }
            description={
              <>
                <div>{t('backgroundJob.description')}</div>
              </>
            }
            spinning={false}
            onDone={onBackgroundJobDone}
            onFailed={onBackgroundJobFailed}
          />
        )}
      </Modal>
    </Row>
  );
};

export default CreateSaasIntegrationPage;
