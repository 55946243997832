import api, { API_V1_PATH } from 'core/api';
import { loadDashboards } from 'redux/ui/dashboard/operations';
import { createReduxResource } from 'core/redux/resources';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import { apiUrl } from 'core/config';
import axios from 'axios';
import { stringify } from 'core/utils/queryString';

export const dashboardsResource = createReduxResource({
  name: 'dashboards',
  customOperations: {
    copyById: {
      callEndpoint: async requestOptions => {
        const { id } = requestOptions;
        return api.copyDashboard({ id }, {});
      },
      onSuccess: ({ dispatch }) => {
        dispatch(loadDashboards());
      }
    },
    loadReviewsCount: {
      parseOptions: false,
      // * endpoint function that fires on operation call
      callEndpoint: async ({ options = {}, cancelTokenSource }) => {
        if (options?.filters?.checklistDefinitionsIdsForFilter) {
          const { checklistDefinitionsIds, checklistDefinitionsIdsForFilter } = options.filters;

          options.filters.checklistDefinitionsIds = checklistDefinitionsIdsForFilter.concat(
            checklistDefinitionsIds
          );
        }

        const res = await axios.get(
          `${apiUrl}${API_V1_PATH}/client_interactions/meta_reviews_count${stringify(
            parseOptionsForApi(options)
          )}`,
          {
            cancelToken: cancelTokenSource.token,
            headers: {
              'Content-Type': 'application/json',
              'access-token': localStorage.getItem('access-token'),
              client: localStorage.getItem('client'),
              uid: localStorage.getItem('uid')
            }
          }
        );
        return {
          ...res,
          body: {
            data: res?.data?.total_count
          }
        };
      },

      // * calls if request was successfull
      onSuccess: ({ result, response, dispatch }) => {
        dispatch({ type: 'uiWidgetSettingsModalReducer/updateAxiosToken', payload: null });
        return camelcaseKeysDeep(response.data);
      }
    }
  }
});
