export default {
  dashboardPage: {
    widget: {
      filtersIsNotInstalled: 'No filters installed',
      unitName: 'Unit',
      needToInstallFilters: 'Filters need to be installed',
      checkFilters: 'Check filters',
      emptyFiltersData: 'No data were found for this set of filters',
      emptyName: 'Name is not given',
      withoutGroups: 'Without a group',
      outdated: {
        title: 'Widget not supported',
        outdatedFlagsAndCommentsWidgets:
          'To continue working, create XL widget "Flags and Comments"'
      },
      conflicts: {
        title: 'The set settings are unavailable',
        description1: 'Due to changes in profile settings, you have lost access to the data.',
        description2:
          'To change widget settings, click "Clear widget" and set new filters. To regain access to the previously set settings, contact the Admin.'
      },
      buttons: {
        more: ' Read more',
        export: 'Export to Excel'
      },
      messages: {
        analyticsSuccessfullyExported: 'Analytics successfully exported',
        exportAnalyticsFailed: 'Export analytics failed',
        needMoreData: 'To build a graph, you need estimates for at least two days'
      },
      utils: {
        emptyName: 'No name found',
        nameIsNotInstalled: 'Name is not set:',
        emptyDescription: 'Description not found',
        allTime: 'All time',
        info: 'Information'
      },
      modal: {
        tooltip: 'Settings',
        title: 'Widget settings',
        buttons: {
          reset: 'Reset widget',
          cancel: 'Cancel',
          save: 'Save'
        },
        messages: {
          widgetSuccessfullyUpdated: 'Widget updated',
          updateWidgetFailed: 'Failed to update widget',
          requiredField: '(Required field)'
        },
        form: {
          type: 'Data type',
          typePlaceholder: 'Select the data type to be displayed',
          name: 'Widget name',
          namePlaceholder: 'Enter widget name',
          communicationDate: 'Communication date',
          communicationsCreationPeriod: 'Communications creation period',
          communicationsCreationPeriodPlaceholder: 'Choose month',
          isWeekendView: 'Show weekends',
          isWeekendViewInfo:
            'Allows you to choose whether columns for weekends included in the specified period will be displayed' +
            ' in the widget, and whether they will be taken into account when calculating the “Total” column.',
          isWeekendViewPlaceholder: 'Select the status of the weekend display',
          reviewDate: 'Evaluation date',
          date: 'Date',
          unit: 'Department',
          departmentBeingAudited: 'Department being audited',
          departmentBeingAuditedPlaceholder: 'Select the department to be checked',
          unitPlaceholder: 'Choose department',
          operators: 'Operators',
          operatorsPlaceHolder: 'Choose operators',
          reviewers: 'Supervisors',
          reviewersPlaceHolder: 'Choose supervisors',
          checklistDefinition: 'Scorecard ',
          taskDefinitions: 'Tags',
          taskDefinitionsPlaceHolder: 'Choose tags',
          isReviewed: 'Review status',
          isReviewedPlaceHolder: 'Choose review status',
          direction: 'Call direction',
          directionPlaceHolder: 'Choose call direction',
          dateFormat: 'Display format',
          dateFormatPlaceHolder: 'Select display format'
        }
      }
    },
    dashboard: {
      emptyDashboard: 'No dashboard created',
      emptyDashboardRows: 'No row is created',
      newDashboard: 'New dashboard',
      loading: 'Loading analytics',
      messages: {
        dashboardSuccessfullyCreated: 'Dashboard successfully created',
        createDashboardFailed: 'Failed to create dashboard'
      },
      buttons: { create: 'Create' },
      reviewsCountShort: 'Evaluations',
      drawer: {
        title: 'Dashboard settings',
        dashboards: {
          title: 'Dashboards',
          confirmDelete: {
            title: 'Are you sure you want to delete this dashboard?',
            description: 'You cannot restore dashboard settings after its deletion'
          },
          messages: {
            rowsLimit: 'No more than 10 rows is available for 1 dashboard',
            dashboardSuccessfullyDeleted: 'Dashboard deleted',
            dashboardDeleteFailed: 'Failed to delete dashboard',
            dashboardSuccessfullyCopied: 'Dashboard copied',
            copyDashboardFailed: 'Failed to copy dashboard',
            getPro: 'Switch to another tariff to create more dashboards. '
          },
          menu: {
            edit: 'Rename dashboard',
            copy: 'Copy dashboard',
            delete: 'Delete dashboard'
          },
          buttons: {
            more: 'More',
            addDashboard: 'Add new dashboard'
          }
        },
        rows: {
          title: 'Widgets',
          confirmDelete: {
            title: 'Are you sure you want to delete row?',
            ok: 'Yes',
            cancel: 'No'
          },
          buttons: { addRow: 'Add row' },
          rowsCount1: 'One widget row',
          rowsCountPT1: 'Row with ',
          rowsCountPT2: 'widgets'
        }
      }
    },
    tableReviewsCountByOperators: {
      table: {
        columns: {
          dates: 'Dates',
          totalScore: 'Total',
          reviewsCount: 'Number of reviews',
          averageScore: 'Average score'
        }
      },
      viewModes: {
        types: {
          table: { name: 'Table' },
          chart: {
            name: 'Chart',
            seriesNames: {
              averageScoreByOperator: 'Average employee score',
              totalAverageScore: 'Average score for all employees'
            },
            yaxis: 'Average score',
            scoreStr: 'point',
            percantageStr: '%',
            table: {
              name: 'Name',
              description: 'Description',
              averageScoreStr: 'Average score (max:'
            }
          }
        }
      }
    },
    tableChecklistItemsByOperators: {
      table: {
        columns: {
          questions: 'Questions',
          groups: 'Question groups',
          totalScore: 'Total score',
          average: 'Average score'
        }
      },
      viewModes: {
        questions: 'Questions',
        groups: 'Question groups'
      }
    },
    tableChecklistItemsHistory: {
      table: {
        columns: {
          dates: 'Dates',
          totalScore: 'Total score',
          reviewsCount: 'Evaluations count',
          average: 'Average score'
        }
      },
      viewModes: {
        questions: 'Questions',
        groups: 'Question groups'
      }
    },
    flagsAndComments: {
      viewModes: {
        operators: 'Operators',
        units: 'Units'
      },
      table: {
        columns: {
          name: 'Name',
          commentsCount: 'Flags and comments count'
        }
      }
    },
    tableChecklistsScoresByOperators: {
      firstColumnNames: {
        operators: 'Name',
        units: 'Unit Name'
      },
      columns: { totalScore: 'Total score' },
      rows: { footer: 'Average score' }
    },
    tableReviewsCountByReviewers: {
      changeViewType: 'Change view',
      firstColumnNames: {
        reviewers: 'Supervisors',
        units: 'Units'
      },
      rows: {
        header: 'Total time for call checks',
        headerInfo:
          'The total duration of verified calls. Takes into account the duration of checked calls as many times as they were checked.',
        footer: 'Total evaluations count'
      },
      viewModes: {
        chart: {
          table: {
            columns: {
              name: 'Name',
              reviewsCount: 'Evaluations',
              reviewsPercentage: '% of evaluations',
              totalReviewsTime: 'Time'
            },
            rows: { total: 'Total' }
          },
          chart: {
            series: {
              reviewed: 'Evaluated by reviewer',
              totalReviewed: 'Total evaluated contacts'
            },
            yAxis: 'Evaluations count'
          }
        }
      }
    },
    tableReviewsWithScores: {
      table: {
        columns: {
          type: 'Type',
          operatorId: 'Operator',
          communicationKey: 'Phone nimber / Communication ID',
          totalScore: 'Total score',
          clientInteractionStartedAt: 'Communication date',
          reviewCreatedAt: 'Evaluation date'
        }
      },
      exportError:
        'You are trying to download over 5000 evaluations. Shorten the period to reduce the number of exported evaluations'
    },
    widgetMeta: {
      longSortMessage: 'Sorting by criteria can take a long time.',
      maxItemsCount: 'The maximum number of checks cannot exceed 1000.',
      foundReviews: 'Found reviews: '
    }
  }
};
