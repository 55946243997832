import { Col } from 'antd';
import ChecklistHistoryModal from 'components/Checklist/ChecklistHistoryModal';
import QuestionDetailsModal from 'components/Questions/QuestionDetailsModal';
import SRow from 'components/Standard/SRow';
import { ANALYTICS_WIDGETS_NAMES, CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import { get, isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getChecklistDefinitionQuestionGroups } from 'redux/selectors/checklistItems/checklistItems';
import { getChecklistsDefinitionsByStatuses } from 'redux/selectors/checklists';
import { updateUserChecklistAnalytics } from 'redux/ui/userAnalyticsPage/operations';
import SCard from 'components/Standard/SCard';
import QuestionsAnalytics from '../QuestionsAnalytics';
import { AllChecklistsAnalytics } from './AllChecklistsAnalytics';
import AllQuestionsAnalytics from './AllQuestionsAnalytics';
import ChecklistDefinitionAnalytics from './ChecklistDefinitionAnalytics';
import EmptyForUserPage from '../EmptyForUserPage';
import SCol from 'components/Standard/SCol';

const AnalyticsChartsPanel = ({ questions }) => {
  const dispatch = useDispatch();

  const loading = useSelector(
    state =>
      state.usersResource.loadByIdStarted ||
      state.usersResource.loading ||
      state.users.loading ||
      state.questionGroupsAnalytics.loading ||
      state.questionsAnalytics.loading,
    isEqual
  );

  const { currentUser } = useSelector(state => state.uiUserAnalyticsPage, isEqual);
  const userId = get(currentUser, 'id', undefined);
  const filters = useSelector(state => get(state.uiUserAnalyticsPage.filters, userId, {}), isEqual);

  const checklistDefinition = useSelector(
    state => get(state.checklistDefinitionsResource.byIds, filters?.checklistDefinitionsIds, {}),
    isEqual
  );

  const { isGroupable } = checklistDefinition;

  const checklistDefinitions = useSelector(state =>
    Object.values(
      getChecklistsDefinitionsByStatuses(state, {
        statuses: [
          CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
          CHECKLIST_DEFINITION_STATUS.ARCHIVED.value
        ]
      })
    )
  );
  const { loadingWidgets, widgets } = useSelector(state => state.analyticsWidgets, isEqual);
  const questionGroups = useSelector(
    state => getChecklistDefinitionQuestionGroups(state, checklistDefinition),
    isEqual
  );

  useEffect(() => {
    dispatch(updateUserChecklistAnalytics({ filters }));
  }, [userId, filters]);

  const { t } = useTranslation();

  if (loading) {
    return <SCard padding="16px" loading />;
  }
  const checklistDefinitionsIds = filters?.checklistDefinitionsIds?.split(',').filter(Boolean);

  if (
    (checklistDefinitionsIds?.includes('all') && checklistDefinitionsIds?.length === 1) ||
    filters?.checklistDefinitionsIds === 'all'
  ) {
    return (
      <AllChecklistsAnalytics
        checklistDefinitions={checklistDefinitions}
        widgets={widgets}
        loadingWidgets={loadingWidgets}
      />
    );
  }

  if (
    (checklistDefinitionsIds?.includes('all-questions') && checklistDefinitionsIds?.length === 1) ||
    filters?.checklistDefinitionsIds === 'all-questions'
  ) {
    return (
      <AllQuestionsAnalytics userId={userId} loadingWidgets={loadingWidgets} widgets={widgets} />
    );
  }

  if (
    !checklistDefinitionsIds?.includes('all') && checklistDefinitionsIds?.length > 1
  ) {
    return (
      <AllChecklistsAnalytics
        checklistDefinitions={checklistDefinitions}
        widgets={widgets}
        loadingWidgets={loadingWidgets}
      />
    );
  }

  const reviewsCount = get(
    widgets,
    [
      ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_CHECKLIST_DEFINITIONS,
      checklistDefinition.id,
      'value'
    ],
    0
  );

  if (!reviewsCount && !loadingWidgets) {
    return <EmptyForUserPage />;
  }

  return (
    <SRow padding="16px" gutter={[0, 16]}>
      <Helmet>
        <title>{t('pagesMeta.userAnalyticsPage.title')}</title>
      </Helmet>

      <ChecklistDefinitionAnalytics
        showName
        checklistDefinition={checklistDefinition}
        loading={loadingWidgets}
        reviewsCount={reviewsCount}
        colorZones={get(widgets, [
          ANALYTICS_WIDGETS_NAMES.CHECKLIST_SCORES_COLOR_ZONE_BY_CHECKLIST_DEFINITIONS,
          checklistDefinition.id
        ])}
        averageScore={get(widgets, [
          ANALYTICS_WIDGETS_NAMES.AVERAGE_SCORE_BY_CHECKLIST_DEFINITIONS,
          checklistDefinition.id,
          'value'
        ])}
        ratingFlags={get(widgets, [
          ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_CHECKLIST_DEFINITIONS,
          checklistDefinition.id
        ])}
      />
      <SCol span={24} marginTop="-16px">
        {Object.values(questionGroups).map(questionGroup => (
          <SRow key={questionGroup.id} gutter={[16, 16]}>
            <Col span={24}>
              <QuestionsAnalytics
                widgets={widgets}
                key={questionGroup.id}
                loading={loadingWidgets}
                questions={questions}
                questionGroup={questionGroup}
                isGroupable={isGroupable}
                reviewsCount={get(widgets, [
                  ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS,
                  userId,
                  'value'
                ])}
              />
            </Col>
          </SRow>
        ))}
      </SCol>
      <QuestionDetailsModal widgets={widgets} loadingWidgets={loadingWidgets} />
      <ChecklistHistoryModal widgets={widgets} loadingWidgets={loadingWidgets} />
    </SRow>
  );
};

export default AnalyticsChartsPanel;
